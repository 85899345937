import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  FaMobile, FaGlobe, FaDatabase, FaCode, FaLayerGroup, FaServer,
  FaShieldAlt, FaRocket, FaTools, FaAward, FaCheckCircle, FaArrowRight,
  FaUserShield, FaLightbulb
} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Header from './Header';
import './Home.css';
import driverspotlogo from './assets/DriverSpot  Logo_D3.png'
import getapp from '../assets/getapp.png'

const Home = () => {
  const heroImage = "https://kartikmehtablog.com/wp-content/uploads/2022/12/AdobeStock_419269782-scaled.jpeg";
  
  const benefits = [
    { icon: <FaUserShield />, title: "Secure & Reliable", description: "Enterprise-grade security" },
    { icon: <FaLightbulb />, title: "Innovative Solutions", description: "Cutting-edge technology" },
    { icon: <FaRocket />, title: "Fast Delivery", description: "Quick turnaround time" }
  ];

  const services = [
    {
      icon: <FaMobile />,
      title: "Mobile Development",
      description: "Native and cross-platform mobile applications that deliver exceptional user experiences.",
      features: [
        "iOS & Android Apps",
        "React Native",
        "Flutter Development",
        "App Store Optimization"
      ],
      color: "#4CAF50",
      image: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?auto=format&fit=crop&w=1740&q=80"
    },
    {
      icon: <FaGlobe />,
      title: "Web Development",
      description: "Modern web applications built with the latest technologies and best practices.",
      features: [
        "Responsive Design",
        "Progressive Web Apps",
        "E-commerce Solutions",
        "Custom Web Portals"
      ],
      color: "#2196F3",
      image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1715&q=80"
    },
    {
      icon: <FaLayerGroup />,
      title: "Full Stack Solutions",
      description: "End-to-end development services for complex business applications.",
      features: [
        "MERN Stack",
        "Cloud Solutions",
        "API Development",
        "Database Design"
      ],
      color: "#9C27B0",
      image: "https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?auto=format&fit=crop&w=1725&q=80"
    }
  ];

  const stats = [
    { number: "100+", label: "Satisfied Clients", icon: <FaAward /> },
    { number: "150+", label: "Projects Completed", icon: <FaRocket /> },
    { number: "5+", label: "Years Experience", icon: <FaTools /> }
  ];

  return (
    <div className="home-container">
      <Header />
      
      {/* Hero Section */}
      <section className="hero-section">
        
        <div className="hero-content">
          <motion.h1 
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Innovate. Create.<br />
            <span className="gradient-text">Transform.</span>
          </motion.h1>
          <motion.p
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Building tomorrow's digital solutions today
          </motion.p>
          
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <motion.div 
              key={index}
              className="benefit-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="benefit-icon">{benefit.icon}</div>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <div className="section-header">
          <span className="section-tag">Our Services</span>
          <h2>Expert Solutions</h2>
          <p>Comprehensive digital services to grow your business</p>
        </div>

        <div className="services-grid">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="service-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="service-image">
                <LazyLoadImage
                  src={service.image}
                  alt={service.title}
                  effect="blur"
                />
                <div className="service-overlay"></div>
              </div>
              <div className="service-content">
                <div className="service-icon" style={{ color: service.color }}>
                  {service.icon}
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <ul className="service-features">
                  {service.features.map((feature, idx) => (
                    <li key={idx}>
                      <FaCheckCircle className="check-icon" />
                      {feature}
                    </li>
                  ))}
                </ul>
               
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      

      {/* CTA Section */}
      <section className="cta-section">
        <div className="cta-content">
          <h2>Ready to Start Your Project?</h2>
          <p>Let's create something amazing together</p>
          <Link to="/contactus" className="cta-button">
            Get Started <FaArrowRight />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
